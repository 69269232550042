import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { throttle } from "lodash";
import { Link, useHistory } from "react-router-dom";
import CustomModal from "../ReactModal";
import jwt_decode from "jwt-decode";
import Navbar from "../Navbar";
import TopBar from "../topbar";
import { propertyService } from "../../_services";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Footer from "../../layout/footer";
import constant from "../../_config/constant";
import Moment from "react-moment";
import Pagination from "react-js-pagination";
import Tippy from "@tippy.js/react";
import { AiOutlinePlus } from "react-icons/ai";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Countdown from "react-countdown";
import ShowMessageModel from "./ShowMessageModel";
import Modal from "react-modal";

export default function ReservationList(props) {
  const history = useHistory();
  const [modalIsOpenReject, setIsOpenReject] = React.useState(false);
  const [requesting, setRequesting] = useState(true);
  const [propertyList, setPropertyList] = useState([]);
  const [filters, setFilters] = useState({});
  const [list, setList] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [isRejectText, setIsrejectText] = useState(false);
  const [bookingId, setBookingId] = React.useState("");
  const [reasonReject, setReasonReject] = React.useState(false);
  const [error, setError] = useState("");

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  
  var limit = 10;

  var data = localStorage.getItem(constant.DATA_KEY);
  const decoded = data ? JSON.parse(data) : {};

  function afterOpenModalReject() {}


  function closeModalReject() {
    setIsOpenReject(false);
  }

  function handleReason(e) {
    if(e.value === "Other"){
      setIsrejectText(true);
    }
    else{
      setIsrejectText(false);
      setReasonReject(e.value);
    }
  }

  const handleSearch = () => {
    setRefreshKey((oldKey) => oldKey + 1);
  };
  const countdownRender = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
       
      return  <p></p>;//  <p>Expired</p>;
    } else {
      
      return (
        <span>
          {/* {`${hours} hr `}
          {`${minutes} min `}
          {`${seconds} Sec`} */}
          {`${hours} : `}
          {`${minutes} : `}
          {`${seconds}`}
        </span>
      );
    }
  };
  const handleRejectBookingConfirm = (status) => 
    {
         // Validation: Ensure a reason is selected or provided
      if (reasonReject == false || reasonReject == "false" ) {
        setError("Please select or provide a reason to reject the booking.");
        return;
      }

      // Clear error and proceed
      setError("");
      setIsOpenReject(false);
      handleBooking(status, bookingId);
    }
    const handleRejectBooking = async (status, id) => {
      setIsOpenReject(true);
      setBookingId(id);
      return;
  }
  

  const throtteledSearch = useCallback(throttle(handleSearch, 900), []);

  const getBedCount = (Arrangements) => {
    if (!Arrangements || !Arrangements.length) return 0;
    return Arrangements.reduce(function(acc, obj) {
      return acc + parseInt(obj.numberOfBeds);
    }, 0);
  };

  function renderActionChip(status, id) {
    switch (status) {
      case "Pending": {
        return (
          <>
            <li
              onClick={() => handleBooking("Accepted", id)}
              className=" tw-cursor-pointer tw-p-2 tw-px-4 p-2 tw-text-xs tw-bg-primary tw-text-white"
            >
              Accept
            </li>
            <li
              onClick={() => handleRejectBooking("Rejected", id)}
              className=" tw-cursor-pointer tw-p-2 tw-px-4 p-2 tw-text-xs tw-bg-primary tw-text-white"
            >
              Reject
            </li>
          </>
        );
      }
      case "Accepted": {
        return (
          <>
            <li
              onClick={() => handleBookingCancle("Cancelled", id)}
              className=" tw-cursor-pointer tw-p-2 tw-px-4 p-2 tw-text-xs tw-bg-primary tw-text-white"
            >
              Cancel
            </li>
          </>
        );
      }
      default: {
        return <></>;
      }
    }
  }
  const [openMessageModel, setOpenMessageModel] = useState(false)
  const showStausMessagesWithNotice = (data) => {
    console.log("Json data -- "+data.status);
    switch (data.status) {
      case "Pending":
      {
        return <>
        Pending 
        <br></br> 
        <Countdown
        renderer={countdownRender}
        // date={new Date(data.createdAt)}
        // date={addHours(new Date(data.createdAt), 24)}
        date={moment(data.createdAt).add(24, "hour")}
      />
      </> 
      }
      case "Cancelled":
      {
        if (data.bookingCancelledBy == "Host" || data.cancelledByHost== true) {
          return `Cancelled by Host`;
        } else if (data.bookingCancelledBy == "Crewmates" || data.cancelledByAdmin== true) {
          return `Cancelled by Admin`;
        } else if (data.bookingCancelledBy == "Guest") {
          return `Cancelled by Guest`;
        }
        else{
          return `Cancelled`;
        }
      }
      case "Cancel_Scheduled":
      {
        if (data.bookingCancelledBy == "Host" || data.cancelledByHost== true) {
          return `Cancelled by Host`;
        } else if (data.bookingCancelledBy == "Crewmates" || data.cancelledByAdmin== true) {
          return `Cancelled by Admin`;
        } else if (data.bookingCancelledBy == "Guest") {
          return `Cancelled by Guest`;
        }
        else{
          return `Cancelled`;
        }
      }
      case "Rejected":
      {
        return <>
        Rejected 
        <br></br> 
        <span>{data.rejectedReason ? <>
        <div className="tw-flex gap-2 tw-items-center tw-cursor-pointer" onClick={()=>setOpenMessageModel(true)}>
        <i  className="fas fa-eye tw-cursor-pointer"/>
        <span>Show</span>
        </div>
        <ShowMessageModel isOpen={openMessageModel} onClose={()=>setOpenMessageModel(false)} text={data.rejectedReason} />
        </> : '-'}</span>
      </> 
        
      }
      default:
        return data.status;
    }
  }

  const pageChange = (page) => {
    setPage(page);
    setRefreshKey((oldKey) => oldKey + 1);
  };

  const handleFliters = (key, value) => {
    if (value) {
      setFilters((e) => {
        const newFilter = { ...e, [key]: value };
        return newFilter;
      });
    } else {
      setFilters((e) => {
        const newFilter = { ...e };
        delete newFilter[key];
        return newFilter;
      });
    }
  };

  function handleFilterReset() {
    history.go(0);
  }

  const handleBooking = async (status, id) => {
    try {
      
      if (status == "Rejected") {
      const res = await propertyService.updateBookingStatus({
        id: id,
        status,
        reason: reasonReject,
      });
      
      if (res.status) {
        toast.success(res.message);
        setRefreshKey((e) => e + 1);
      } else toast.error(res.message);
    }
    else{
        const res = await propertyService.updateBookingStatus({
          id: id,
          status,
        });
  
        if (res.status) {
          toast.success(res.message);
          setRefreshKey((e) => e + 1);
        } else toast.error(res.message);

    }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };


  const handleBookingCancle = async (status, id) => {
    try {
      
  
      const res = await propertyService.cancelBookingByDateAdmin(id);
      
      if (res.status) {
        toast.success(res.message);
        setRefreshKey((e) => e + 1);
      } else toast.error(res.message);
    
  
    } catch (error) {
      toast.error("Something went wrong");
    }
  };



  useEffect(() => {
    setRequesting(true);
    propertyService.bookinglist({ page, limit: 10, ...filters, search }).then(
      (objS) => {
        setList(objS.data.docs);
        setTotal(objS.data.total);
        setRequesting(false);
        window.scrollTo({ top: 0, behavior: "smooth" });
      },
      (error) => {
        console.log(error);
        setRequesting(false);
      }
    );
  }, [refreshKey, filters]);

  useEffect(() => {
    propertyService.allPropertylist().then(
      (objS) => {
        setPropertyList(objS.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  return (
    <div id="main-wrapper">
      <Modal
        isOpen={modalIsOpenReject}
        onAfterOpen={afterOpenModalReject}
        onRequestClose={closeModalReject}
        style={customStyles}
        contentLabel="Self Reject"
        ariaHideApp={false}
      >
        <div>
          <div className="modal-header">
            <h5 className="modal-title">Reject Booking</h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={closeModalReject}
            ></button>
          </div>
          <div className="modal-body">
          <label htmlFor="reason" className="tw-block tw-mt-4 tw-mb-2 tw-font-semibold">
            Select reason to reject reservation *
          </label>
          <select
            onChange={(e) => handleReason( e.target)}
            className="form-control form-control-sm  wide"
            name="rentalType"
            required
          >
            <option value="">Select</option>
            <option value={"No beds are available for the selected dates"}>No beds are available for the selected dates</option>
            <option value={"The property is discontinuing its rental business"}>The property is discontinuing its rental business</option>
            <option value={"The guest is making the booking on behalf of another person"}>The guest is making the booking on behalf of another person</option>
            <option value={"Special requests that the Host cannot accommodate"}>Special requests that the Host cannot accommodate  </option>
            <option value={"Other"}>Other</option>
          </select>
          <br></br>
          {error && <p className="tw-text-sm tw-text-red-500 tw-mt-2">{error}</p>}
          { isRejectText ?
          <div>
          <label htmlFor="reason" className="tw-block tw-mt-4 tw-mb-2 tw-font-semibold">  
          Enter your reason to reject reservation *  
        </label>
        <input
            id="reasonReject"
            type="text"
            className="form-control form-control-sm"
            onChange={(e) => {
              if (e.target.value.length <= 225) {
                setReasonReject(e.target.value);
              }
            }}
            placeholder="Enter your reason here..."
          />
          <span className="tw-text-sm tw-text-gray-500">
            {reasonReject.length}/225 characters
          </span>
        </div>
        :
        <>
        </>

          }
          
          </div>
          <div className="mt-4 tw-flex tw-justify-end tw-gap-4">
        <button onClick={closeModalReject} 
        className="tw-text-center  tw-p-2 tw-px-4 p-2 tw-border-none tw-text-xs btn-secondary tw-rounded-md tw-text-white">
          Cancel
        </button>
        <button onClick={() => handleRejectBookingConfirm("Rejected")}
        className="tw-text-center  tw-p-2 tw-px-4 p-2 tw-border-none tw-text-xs btn-secondary tw-rounded-md tw-text-white"
        >
          Reject Booking
        </button>
      </div>
        </div>
      </Modal>
      <ToastContainer />
      <TopBar
        user={decoded}
        showSearch={true}
        handleSearch={(e) => {
          setSearch(e.target.value);
          throtteledSearch(e.target.value);
        }}
        search={search}
        title="Reservation List"
      />

      <div className="deznav">
        <div className="deznav-scroll">
          <Navbar />
        </div>
      </div>

      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <h4 className="tw-text-xl tw-font-medium">Filter</h4>
              <div className="tw-grid tw-grid-cols-2  md:tw-grid-cols-6 tw-gap-2 tw-mb-4">
                <div>
                  <label className="text-label form-label">Check In</label>
                  <input
                    onChange={(e) => handleFliters("start", e.target.value)}
                    type="date"
                    name="checkInData"
                    className="form-control form-control-sm"
                    placeholder="Check In"
                  />
                </div>
                <div>
                  <label className="text-label form-label">Check Out</label>
                  <input
                    onChange={(e) => handleFliters("end", e.target.value)}
                    type="date"
                    name="checkOutData"
                    className="form-control form-control-sm"
                    placeholder="Check In"
                  />
                </div>
                {/* <div>
                  <label className="form-label">Rental Type</label>
                  <select
                    onChange={(e) => handleFliters("pt", e.target.value)}
                    className="form-control form-control-sm  wide"
                    name="rentalType"
                    required
                  >
                    <option value="">Select</option>
                    <option value="Crashpad">Crashpad</option>
                    <option value="Entire Place">Entire Place</option>
                  </select>
                </div> */}
                <div>
                  <label className="form-label">Listing Name</label>
                  <select
                    onChange={(e) => handleFliters("ppt", e.target.value)}
                    className="form-control form-control-sm  wide"
                    name="rentalType"
                    required
                  >
                    <option value="">All</option>
                    {propertyList.map((curr) => (
                      <option value={curr.id} key={curr.id}>
                        {curr.listingName}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="form-label">Bed Type</label>
                  <select
                    onChange={(e) => handleFliters("bt", e.target.value)}
                    className="form-control form-control-sm  wide"
                    name="rentalType"
                    required
                  >
                    <option value="">Select</option>
                    <option value="Hot bed">Hot Bed</option>
                    <option value="Cold bed">Cold Bed</option>
                  </select>
                </div>
                <div>
                  <label className="form-label">Status</label>
                  <select
                    onChange={(e) => handleFliters("s", e.target.value)}
                    className="form-control form-control-sm  wide"
                    name="rentalType"
                    required
                  >
                    <option value="">Select</option>
                    <option value="Pending">Pending</option>
                    <option value="Accepted">Accepted</option>
                    <option value="Expired">Expired</option>
                    <option value="Completed">Completed</option>
                    <option value="Rejected">Rejected</option>
                    <option value="Cancelled">Cancelled</option>
                  </select>
                </div>
                <div>
                  <label className="text-label form-label">Reset Filter</label>
                  <button
                    onClick={handleFilterReset}
                    className="btn-sm btn btn-primary form-control form-control-sm"
                  >
                    Reset
                  </button>
                </div>
              </div>
              {/* <div>
                <div className="tw-my-4">
                  <label className="form-label">Search</label>
                  <input type="text" name="" id="" className="form-control" />
                </div>
              </div> */}
              {requesting && (
                <div className="tw-my-10 tw-flex tw-items-center tw-justify-center">
                  <div className="bg-white tw-w-max rounded shadow-md p-4">
                    <div className="loader"></div>
                  </div>
                </div>
              )}
              <div className="tab-content">
                <div className="tab-pane active show" id="All">
                  <div className="table-responsive">
                    <table
                      className="table card-table  display mb-4 dataTablesCard booking-table room-list-tbl table-responsive-lg "
                      id="guestTable-all"
                    >
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>Status</th>
                          <th>Reservation Id</th>
                          <th>Confirmation Code</th>
                          <th>Host Name</th>
                          <th>Guest Name</th>
                          <th>Listing Name</th>
                          <th>Booking Type</th>
                          {/* <th>Room Name</th> */}
                          <th>Total Amount</th>
                          <th>Check In</th>
                          <th>Check Out</th>
                          <th>Weekly Payout Dates</th>
                          <th>Created At</th>
                          <th>Updated At</th>
                          <th>Payout Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list.map((booking) => (
                          <tr key={booking.id}>
                            <td className="tw-flex tw-flex-col tw-space-y-2">
                              {renderActionChip(booking.status, booking.id)}
                              <Link to={`/reservation/${booking.id}`}>
                                <li className="tw-text-center  tw-p-2 tw-px-4 p-2 tw-text-xs tw-bg-primary tw-text-white">
                                  Details
                                </li>
                              </Link>
                            </td>
                            <td>
                              <span className="fs-16">{showStausMessagesWithNotice(booking)}</span>
                            </td>
                            <td>
                              <span className="fs-16">
                                {booking.bookingId || "--"}{" "}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16">
                                {booking.confirmationCode || "--"}{" "}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16">
                                {booking.hostId.name}{" "}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16">
                                {booking.userId.name}{" "}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16">
                                {" "}
                                {booking.propertyId.listingName}{" "}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16">
                                {" "}
                                {booking.propertyId.bookingType === "Flexible"
                                  ? `Flexible ${booking.bookingType}`
                                  : booking.bookingType}{" "}
                              </span>
                            </td>
                            {/* <td>
                              <span className="fs-16">
                                {booking.selectedRoom}
                              </span>
                            </td> */}
                            <td>
                              <span className="fs-16">
                                {(booking.totalPrice || 0).toLocaleString(
                                  "en-US",
                                  {
                                    style: "currency",
                                    currency: "USD",
                                  }
                                )}
                              </span>
                            </td>

                            <td>
                              <span className="fs-16">
                                {moment(
                                  booking.checkInDate.split("T")[0]
                                ).format("MMMM Do, YYYY")}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16">
                                {booking.checkOutDate
                                  ? moment(
                                      booking.checkOutDate.split("T")[0]
                                    ).format("MMMM Do, YYYY")
                                  : "--"}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16">{"--"}</span>
                            </td>
                            <td>
                              <span className="fs-16">
                                <Moment format="MMMM Do, YYYY">
                                  {booking.createdAt}
                                </Moment>
                              </span>
                            </td>
                            <td>
                              <span className="fs-16">
                                <Moment format="MMMM Do, YYYY">
                                  {booking.updatedAt}
                                </Moment>
                              </span>
                            </td>
                            <td>
                              <span className="fs-16">{"--"}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Pagination
            activePage={page}
            itemsCountPerPage={limit}
            totalItemsCount={total}
            pageRangeDisplayed={10}
            onChange={pageChange}
          />
        </div>
      </div>

      <Footer />
    </div>
  );
}
